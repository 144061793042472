import { ContextProps, GlobalContext } from 'context/global-context';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { FiX } from 'react-icons/fi';
import Button from 'components/formitems/button';
import { mqUntil } from 'styles/styles';

export type MessageBarProps = {
  loading?: boolean;
};

const cookieKey = 'bd-messagebox-closed';

export const MessageBar: React.FC<MessageBarProps> = () => {
  const {
    shop: { messageBar },
  } = useContext<ContextProps>(GlobalContext);
  const [show, setShow] = useState(false);

  const close = (): void => {
    Cookies.set(cookieKey, '1');
    setShow(false);
  };

  useEffect(() => {
    if (messageBar) {
      const closed = Cookies.get(cookieKey) === '1';
      setShow(!closed);
    }
  }, [messageBar]);

  if (!show) return null;

  return (
    <StyledMessageBar>
      <div
        className="inner"
        dangerouslySetInnerHTML={{ __html: messageBar }}
      ></div>
      <Button
        className="close-btn"
        onClick={close}
        icon={FiX}
        size="small"
        transparent
      />
    </StyledMessageBar>
  );
};

export const StyledMessageBar = styled.div`
  padding: 2rem var(--side-padding);
  background: var(--cookie-consent-background);
  color: var(--cookie-consent-foreground);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    text-align: center;
    font-weight: 600;
    flex: 1 1 0;
    padding-left: 26px;
  }

  ${mqUntil.tablet} {
    align-items: flex-start;

    .close-btn {
      margin-top: -5px;
    }

    .inner {
      text-align: left;
      padding-left: 0;
    }
  }
  /* .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  } */
`;

export default MessageBar;
