/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SimplePage } from 'data/types';
import { ContextProps, GlobalContext } from 'context/global-context';
import React, { useContext } from 'react';
import { mqFrom } from 'styles/styles';
import { isExternalLink } from 'utils/helpers';

type ItemProps = {
  item: SimplePage;
};

type NavigationProps = {
  collapsed: boolean;
};

type StyledProps = {
  collapsed: boolean;
};

const NavLink: React.FC<ItemProps> = ({ item }) => {
  const router = useRouter();
  const path = router.asPath.split('?')[0];
  // if (item.children?.length) {
  //   return (
  //     <button
  //       type="button"
  //       className={`no-link ${
  //         item.href === router?.asPath ||
  //         (item.children || []).find((c) => c.href === router?.asPath)
  //           ? 'active'
  //           : ''
  //       }`}
  //     >
  //       {item.name}
  //     </button>
  //   );
  // }

  if (item.type === 'link') {
    const addOns = isExternalLink(item?.href || '')
      ? { target: '_BLANK', rel: 'noopener noreferrer' }
      : {};
    return (
      <a href={item?.href} {...addOns}>
        {item.name}
      </a>
    );
  }
  return (
    <Link prefetch={false} href={item?.href}>
      <a
        className={`no-link ${
          item.href === path ||
          (item.children || []).find((c) => c.href === path)
            ? 'active'
            : ''
        }`}
      >
        {item.name}
      </a>
    </Link>
  );
};

const Navigation: React.FC<NavigationProps> = ({ collapsed }) => {
  const { pages = [] } = useContext<ContextProps>(GlobalContext);

  return (
    <NavigationStyled collapsed={collapsed} role="navigation">
      <ul>
        {pages
          .filter((item) => !item.hideInNavigation)
          .map((item) => {
            const children =
              item.children?.filter((item) => !item.hideInNavigation) || [];

            return (
              <li
                key={item._id}
                className={children.length > 20 ? 'mega-menu' : ''}
              >
                <NavLink item={item} />
                {children.length ? (
                  <ul
                    className={`navigation-dropdown ${
                      children.length > 10
                        ? children.length > 20
                          ? 'cols three-cols'
                          : 'cols two-cols'
                        : ''
                    }`}
                  >
                    {children.map((subitem) => (
                      <li key={subitem._id}>
                        <NavLink item={subitem} />
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            );
          })}
      </ul>
    </NavigationStyled>
  );
};

const NavigationStyled = styled.nav<StyledProps>`
  /* transition: height 0.2s ease-in-out; */
  display: flex;
  flex-direction: row-reverse;

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    transition: height 0.2s ease-in-out;
    align-items: center;

    > li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      padding: 0 0.6rem;
      flex: 1 1 0;
      /* &.mega-menu {
        position: static;
      } */

      > a,
      > button {
        font-family: var(--font-family-display);
        color: var(--header-foreground);
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: 500;
        display: block;
        position: relative;
        line-height: 1rem;
        /* padding: 2rem; */
        white-space: nowrap;

        &:hover,
        &.active {
          color: var(--primary-background);
        }
      }

      ${mqFrom.wide} {
        padding: 1rem 1rem;
        > a,
        > button {
          font-size: 1rem;
        }
      }

      &:hover,
      &:focus {
        .navigation-dropdown {
          display: block;
          z-index: 100;
        }
      }
    }
  }

  /* // DROP DOWN SUBMENU */
  .navigation-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 230px;
    background: var(--header-dropdown-background);
    height: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: background 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding: 1rem;

    &.two-cols {
      right: -20%;
      left: auto;
      columns: 2;
    }
    &.three-cols {
      right: -100%;
      left: auto;
      columns: 3;
    }

    li {
      &:hover {
        /* background: var(--primary-background); */

        a {
          color: var(--primary-background);
          padding-left: 1.4rem;
          /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); */
        }
      }
    }

    a {
      font-size: 0.9rem;
      color: var(--header-dropdown-foreground);
      cursor: pointer;
      display: block;
      font-family: var(--font-family-display);
      padding: 0.6rem 1rem;
      font-weight: 500;
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
      white-space: nowrap;
    }
  }
`;

export default Navigation;
