import { FC, useContext } from 'react';
import styled from 'styled-components';
import { ContextProps, GlobalContext } from 'context/global-context';
import { mqFrom } from 'styles/styles';
import ShoppingBagIcon from 'icons/shoppingbag';
import { useOrderStore } from 'data/orders';

const CartButton: FC = () => {
  const { setPopupState } = useContext<ContextProps>(GlobalContext);
  const itemsCount: number = useOrderStore(
    (state) => state.order?.basket?.totalItems || 0
  );

  return (
    <StyledCartButton
      type="button"
      className="cart-button"
      onClick={() => {
        setPopupState((val) => (val === 'checkout' ? 'none' : 'checkout'));
      }}
    >
      <div className="inner">
        <ShoppingBagIcon size={24} />
        <div className="badge small">{itemsCount}</div>
      </div>
    </StyledCartButton>
  );
};
const StyledCartButton = styled.button`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  ${mqFrom.tablet} {
    padding: 0 2rem;
  }

  .inner {
    position: relative;
  }

  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: var(--primary-background);
    color: var(--primary-foreground);
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default CartButton;
